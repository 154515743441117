import React from 'react'

import './style.scss'

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="contacts__row">
        <div className="contacts__item">
          <div className="contacts__subtitle email">Email</div>
          <a href="mailto:cva@cvatec.com" target="_blank" rel="noopener noreferrer" className="contacts__link">
            cva(ā)cvatec•com
          </a>
          <a href="mailto:vav@cvatec.com" target="_blank" rel="noopener noreferrer" className="contacts__link">
            vav(ā)cvatec•com
          </a>
        </div>
      </div>
      <div className="contacts__row">
        <div className="contacts__item">
          <div className="contacts__subtitle phone">Телефон</div>
          <a href="tel:+79001306663" target="_blank" rel="noopener noreferrer" className="contacts__link">
            +7-(900)130-6663
          </a>
          <a href="tel:+79001303666" target="_blank" rel="noopener noreferrer" className="contacts__link">
            +7-(900)130-3666
          </a>
        </div>
      </div>
      <div className="contacts__row">
        <div className="contacts__item">
          <div className="contacts__subtitle address">Адрес</div>
          <div className="contacts__desc">Театральный пр., 85,</div>
          <div className="contacts__desc">Ростов-на-Дону,</div>
          <div className="contacts__desc">Ростовская обл., 344016</div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
