/** @format */

import React from 'react'
import PropTypes from 'prop-types'
import Preloader from '../Preloader'
import './button.scss'

function Button({color, text, size, onClick, disabling, type, form, status, className = '', isLoading = false}) {
  return (
    <button
      form={form}
      type={type}
      className={`button ${color ? color : ''} ${size ? size : ''} ${disabling ? `disabled` : ''} ${
        status ? status : ''
      } ${className}`}
      onClick={onClick}>
      {isLoading && <Preloader show transparent={true} />}
      {text ? text : 'Button'}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  size: PropTypes.string,
  disabling: PropTypes.bool,
  status: PropTypes.string,
  form: PropTypes.string,
}

export default Button
