import React from 'react'

import {Carousel} from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import './styles.scss'

const CustomCarousel = ({images}) => {
  return (
    <div className="custom-carousel">
      <Carousel
        autoPlay
        // centerMode={true}
        dynamicHeight={true}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        stopOnHover={false}
        animationHandler="fade"
        transitionTime={1500}>
        {images &&
          images.length > 0 &&
          images.map(image => {
            return (
              <div key={image.id}>
                <img src={image.src} alt="" />
              </div>
            )
          })}
      </Carousel>
    </div>
  )
}

export default CustomCarousel
