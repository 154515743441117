import React from 'react'
// import {useTranslation} from 'react-i18next'

import './style.scss'

const Footer = () => {
  // const {t} = useTranslation()
  // let [setCode] = useState('ru')

  // useEffect(() => {
  //   setCode(t('get_current_language_code'))
  // }, [setCode, t])

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__copyright">© 2024 CVA Tec. Co. All rights reserved</div>
        <div className="footer__socials">
          <div className="social-list"></div>
        </div>
        {/* <div className="footer__lang">
          <LangSwitcher code={code} />
        </div> */}
      </div>
    </div>
  )
}

export default Footer
