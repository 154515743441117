import React from 'react'
import {BeatLoader} from 'react-spinners'

import './Preloader.scss'

const Preloader = ({show, transparent = false, dotsSize = 20}) => {
  return (
    <div className={`preloader ${show ? 'show' : ''} ${transparent ? 'transparent' : ''}`}>
      <BeatLoader size={dotsSize} color={'#21d439'} loading={true} />
    </div>
  )
}

export default Preloader
