import axios from 'axios'
import {ProfileToken} from '../helpers/token'

export const registration = async formData => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/autorized_users`, formData)
    return res.data
  } catch (err) {
    throw err
  }
}

export const userLogin = async formData => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/auth/`, {
      ...formData,
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const sendContactForm = async formData => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/send_question`, {
      ...formData,
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export const getUserData = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/user`, {
      headers: {
        Authorization: `token ${ProfileToken.get()}`,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}
