import React, {useState, createContext} from 'react'

const UserContext = createContext(null)

export const UserContextProvider = ({children}) => {
  const [userData, updateUserData] = useState(null)
  const [activeTab, updateActiveTab] = useState('companies')

  const contextValue = {
    userData,
    updateUserData,
    activeTab,
    updateActiveTab,
  }
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export default UserContext
