import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './translation/en.json'
import ru from './translation/ru.json'

// the translations
const resources = {
  en: {translation: en},
  ru: {translation: ru},
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'ru',
    detection: {
      // order and from where user language should be detected
      order: [localStorage, navigator],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(err => console.error('Error loading translation files', err))
// .on('languageChanged', () => {})

const isEng = () => i18n.language === 'en'
const isRu = () => i18n.language === 'ru'
export {i18n, isEng, isRu}
