import React from 'react'

import './input.scss'

const Input = ({
  inputName = '',
  inputPlaceholder = '',
  inputType = '',
  inputClass = '',
  inputDisable = false,
  isError = false,
  inputAutocomplete = '',
  inputDefaultValue = '',
  inputLabel,
  inputRef,
  onChange,
  onKeyPress,
}) => {
  return (
    <div className="input-wrapper">
      {inputLabel && (
        <label className="input-label" htmlFor={`${inputName}-input `}>
          {inputLabel}
        </label>
      )}
      {inputType === 'textarea' ? (
        <textarea
          id={`${inputName}-input `}
          name={inputName}
          onChange={onChange}
          onKeyPress={onKeyPress}
          defaultValue={inputDefaultValue}
          className={`input text ${inputClass} ${isError ? 'error' : ''} ${inputLabel ? 'withLabel' : ''}`}
          type={inputType}
          placeholder={inputPlaceholder}
          autoComplete={inputAutocomplete}
          disabled={inputDisable}
          ref={inputRef}
        />
      ) : (
        <input
          id={`${inputName}-input `}
          name={inputName}
          onChange={onChange}
          onKeyPress={onKeyPress}
          defaultValue={inputDefaultValue}
          className={`input text ${inputClass} ${isError ? 'error' : ''} ${inputLabel ? 'withLabel' : ''}`}
          type={inputType}
          placeholder={inputPlaceholder}
          autoComplete={inputAutocomplete}
          disabled={inputDisable}
          ref={inputRef}
        />
      )}
      {/* {isError.length > 0 && (
        <div className="error-label">{isError[0].desc}</div>
      )} */}
    </div>
  )
}

export default Input
