import React from 'react'
import CustomButton from '../../components/ui/CustomButton'

import './styles.scss'

const ErrorPage = ({history}) => {
  return (
    <div className="error">
      <div className="error__wrapper">
        <div className="error__top">404</div>
        <div className="error__desc">Page Not Found</div>
        <div className="error__button">
          <CustomButton text="BACK TO HOME PAGE" color="blue" onClick={e => history.push('/')} />
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
