import React from 'react'
import ContactForm from '../../components/blocks/forms/ContactForm'
import Contacts from '../../components/blocks/index/Contacts/Contacts'
import Map from '../../components/blocks/index/Map/Map'
import {sendContactForm} from '../../services/user'

import CustomCarousel from '../../components/ui/Carousel'

// import bg1 from './logo.png';
// import bg2 from './logo.png';
// import bg3 from './logo.png';

import './style.scss'

const mainPage = ({history}) => {
  const submitForm = data => sendContactForm(data).then(response => {})

  return (
    <div className="main-page">
      <div className="main-page__wrapper">
        <div className="main-page__top">
          <div className="main-page__top-title">
            <h2>Безопасность в Ваших руках с AB-01 </h2>
          </div>
          <div className="main-page__top-desc">Надежное Устройство для Экстренного Оповещения</div>

          <div className="main-page__carousel">
            <CustomCarousel
              images={[
                {id: 0, src: require('../../images/button-bg-1.jpg')},
                {id: 1, src: require('../../images/bg-button-3.jpg')},
                {id: 2, src: require('../../images/bg-button-5.jpg')},
                {id: 2, src: require('../../images/bg-button-4.jpg')},
              ]}
            />
          </div>
        </div>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">
              Устройство ABL-01 используется для оперативной помехозащищенной передачи сигнала оповещения по
              беспроводным каналам связи на другое устройство ABL-01. Устройство обладает устойчивостью к перехвату,
              подавлению сигнала и высоким соотношением сигнал\шум в приемном тракте. Что обеспечивает эффективную
              передачу и прием сигнала оповещения в сложных условиях городской застройки и устойчивость к блокированию и
              подделки сигнала оповещения.
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Основные Характеристики:</div>

            <ul className="main-page__list">
              <li>Повышенная помехозащищенность и защита от подделки сигналов</li>
              <li>Управление с помощью кнопок для сигналов "Тревога" и "Внимание"</li>
              <li>Дальность приема до 400* метров, время работы в ожидании до 50** часов</li>
              <li>Количество оповещений при среднем времени ожидания 45 часов - 60***</li>
              <li>Работоспособность в температурном диапазоне от -5 до +45°С</li>
              <li>Автоматическая повторная отправка сигналов при отсутствии подтверждения.</li>
              <li>Защита от ложного срабатывания 0,3 сек</li>
            </ul>
            <ul className="main-page__sub-desc">
              <li className="main-page__sub-desc-item">
                * дистанция уверенного приема зависит от количества препятствий на пути радиосигнала и уровня шума
                радиоэфира
              </li>
              <li className="main-page__sub-desc-item">
                ** Время работы устройства в режиме приема (ожидания) зависит от количества срабатываний оповещения и
                температуры окружающей среды
              </li>
              <li className="main-page__sub-desc-item">
                *** Количество оповещений зависит от времени ожидания и температуры окружающей среды.
              </li>
            </ul>
          </div>
        </section>
        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Проблемы и Угрозы</div>
            <ul className="main-page__list">
              <li>Необходимость надежной связи в экстренных ситуациях</li>
              <li>Потребность в устройстве, способном работать в сложных городских условиях.</li>
            </ul>
          </div>
        </section>
        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Решения и Преимущества</div>
            <ul className="main-page__list">
              <li>Устройство AB-01 обеспечивает надежную и быструю связь в чрезвычайных ситуациях.</li>
              <li>Простота использования и долговечность делают его незаменимым помощником.</li>
            </ul>
          </div>
        </section>
        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__section-row">
              <div className="main-page__section-col">
                <img width="220px" src={require('../../images/button.png')} alt="" />
              </div>
              <div className="main-page__section-col">
                <div className="main-page__title">Функциональные возможности AB-01:</div>
                <ul className="main-page__list">
                  <li> Передача сигнала «Тревога» - однократное нажатие на Кнопка 1</li>
                  <li> Индикация режима передачи сигнала «Тревога» - Светодиод 1</li>
                  <li> Передача сигнала «Внимание» - однократное нажатие на Кнопка 2</li>
                  <li> Индикация режима передачи сигнала «Внимание» - Светодиод 2</li>
                  <li>
                    Индикация сигнала доставки сообщения – одновременно кратковременно светятся Светодиод 1 и Светодиод
                    2, вибросигнал
                  </li>
                  <li>
                    При отсутствии сигнала подтверждения доставки - автоматически до 6ти попыток повторная отправка
                    сигнала
                  </li>
                  <li> Индикация принятого сигнала «Тревога» - Светодиод 1, вибросигнал</li>
                  <li> Индикация принятого сигнала «Внимание» - Светодиод 2, вибросигнал</li>
                  <li>
                    Одновременное нажатие Кнопка 1 + Кнопка 2 длительностью 5 сек. – проверка уровня заряда
                    аккумулятора: 4 мигания светодиодами 100-75%, 3 мигания 75-50%, 2 мигания 50- 25%, 1 мигание 25-5%.
                    На уровне 5% заряда устройство переходит в спящий режим без возможности приема оповещений для
                    приемника. Для передатчика – попытка отправить оповещение используя остаточный заряд.
                  </li>
                  <li>
                    Одновременное нажатие Кнопка 1 + Кнопка 2 длительностью 15 сек. – Отключает \ Включает режим приема
                    на приемнике и передатчике.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Кто мы такие?</div>
            <p className="main-page__desc">
              Позвольте представить Вам нашу компанию «CVA Technologies», компания которая является разработчиком
              программного обеспечения и производителем современных электронных систем.
            </p>
            <p className="main-page__desc">
              Нашей компанией была создана система обнаружения и расследования инцидентов угроз безопасности нового
              поколения (NGIDS, Next Generation Intrusion Detection Systems), которая благодаря применению нейронных
              сетей и спецсредств беспроводного контроля является инновационной мерой противодействия кибератакам.
            </p>
            <p className="main-page__desc">
              По данному проекту был одобрен и успешно выполнен государственный Грант Старт-ЦТ. Программное обеспечение
              выполнено в соответствии с ТЗ, испытано и прошло экспертизу Фонда Содействия Инновациям. Получено
              свидетельство регистрации интеллектуальной собственности на программу NGIDS NeuroFortress.
            </p>
            <p className="main-page__desc">
              В рамках развития проекта мы, используя технологию искусственного интеллекта и уже успешно проверенные
              разработки, создали инновационную системы реагирования на киберугрозы, контроль сотрудников и сетевой
              среды , и систему обеспечения безопасности корпоративной инфраструктуры.
            </p>
            <p className="main-page__desc">
              Многолетний опыт работы позволяет решать любые задачи в разработке программного обеспечения. В своих
              разработках мы используем самые новейшие и передовые технологии, что позволит Вам получить совершенно
              новый уровень информационной безопасности.
            </p>
          </div>
        </section>
        {/* <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Нашими конкурентными преимуществами являются:</div>
            <ul className="main-page__cons">
              <li>Идентификация ранее неизвестных типов кибератак</li>{' '}
              <li>Параллельный анализ трафика различных информационных сред</li> <li>Высокая скорость реакции</li>
              <li>
                Возможность для нейронной сети полностью использовать потенциал «самообучения» и выходить за пределы
                базы знаний экспертной системы
              </li>
              <li>Отсутствие потребности регулярного обновления базы знаний</li>
              <li>Контроль беспроводных сетей и сред умных устройств (интернет-вещей)</li>
              <li>Планируется поддержка сертифицированных в РФ сред для интеграции (например, в рамках «ГосСОПКА»)</li>
            </ul>
          </div>
        </section> */}

        <div className="main-page__bottom">
          <div className="main-page__title">
            Не упустите шанс обеспечить свою безопасность - свяжитесь с нами сегодня!
          </div>
          {/* <a
            className="main-page__bottom-button"
            href="https://cvatec.com/index.php/ru/neurofortress-ru/informatsiya-o-produktakh-neurofortress">
            Заказать
          </a> */}
        </div>
        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title centered">Контактная информация</div>
            <div className="main-page__section-row">
              <div className="main-page__section-col">
                <ContactForm formMethod={submitForm} />
              </div>
              <div className="main-page__section-col">
                <Contacts />
              </div>
            </div>
          </div>
        </section>
        <Map />
        {/* <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__section-row">
              <div className="main-page__section-col">
                <ContactForm formMethod={submitForm} />
              </div>
              <div className="main-page__section-col">
                <Contacts />
              </div>
            </div>
          </div>
        </section>
        <Map /> */}
      </div>
    </div>
  )
}

export default mainPage
